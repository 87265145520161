.all-recuiter-filter-list {
    display: none;
}

.find-search-filter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .find-search-filter-container-inner {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;

        .find-search-filter-container-filter {
            width: 25%;
            display: flex;
            flex-direction: column;
            border-radius: 14px;
            border: 1px solid rgba(217, 234, 244, 1);
            padding: 16px;
            gap: 15px;
            position: sticky;
            left: 0;
            top: 6rem;
            max-height: 80vh;
            /* Adjust as per the available space */
            overflow-y: auto;

            .close-filter-sidebar {
                display: none;
            }

            .applicant-filter-heading {
                display: flex;
                justify-content: space-between;

                h6 {
                    font-size: 14px;

                    &:nth-child(1) {
                        font-weight: 600;
                        color: black;
                    }

                    &:nth-child(2) {
                        font-weight: 400;
                        color: rgba(0, 105, 166, 1);
                    }
                }
            }

            .applicant-filter-location {

                h6 {
                    font-size: 14px;
                    font-weight: 500;
                }

                .city-checkbox {
                    display: flex;
                    gap: 10px;

                    label {
                        font-size: 14px;
                        color: rgba(84, 104, 129, 1);
                    }
                }

                .price-filter-input {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    // border: 1px solid red;
                    align-items: center;

                    input {
                        width: 45%;
                        outline: none;
                        border: 1px solid grey;
                        border-radius: 4px;
                        padding: 3px;

                        &::placeholder {
                            font-size: 14px;
                            // text-align: center;
                            padding-left: 4px;
                        }
                    }
                }
            }
        }

        .all-recuiter-filter-container-card {
            width: 70%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            // border: 1px solid red;
            gap: 1rem;
            flex-wrap: wrap;

            .filter-industry {
                width: 100%;
                // border: 1px solid red;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                gap: 8px;
                font-size: 12px;

                .industry-item {
                    border: 1px solid grey;
                    padding: 6px 16px;
                    border-radius: 22px;
                }

            }

            .allrecuiter-content {
                width: 48%;
                // 
                height: 400px;
                border: 2px solid rgba(238, 238, 238, 1);
                // border: 1px solid green;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 10px;
                padding: 1rem;
                border-radius: 6px;
                position: relative;

                &:hover {
                    box-shadow: 8px 8px 18px #0000001A;
                }

                .flag-project-container {
                    width: 100%;
                   

                    img {
                        position: absolute;
                        right: 3px;
                        top: 3px;
                        width: 40px;
                        height:40px;
                    }
                }


                .job-title {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h5 {
                        font-size: 15px;
                        font-weight: 600;
                        color: black;
                        text-transform: capitalize;
                    }

                    h6 {
                        font-weight: 300;
                        font-size: 13px;
                        color: rgba(171, 171, 171, 1);
                    }

                }

                .job-occupationskill {
                    font-size: 17px;
                    font-weight: 500;
                    color: black;
                }

                .job-hostedby {
                    font-size: 14px;
                    color: rgb(166, 168, 170);
                    font-weight: 300;
                }
                .applicant-location-name{
                    font-size: 14px;
                    color: grey;
                }

                .job-category {
                    font-size: 14px;
                    color: rgba(94, 102, 112, 1);
                    font-weight: 300;
                }

                .hiretalent-content-about {
                    font-size: 15px;
                    text-align: justify;
                    overflow-wrap: break-word;
                    max-width: 100%;
                    color: grey;
                    white-space: normal;

                }

                .hiretalent-education {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    gap: 3px;

                    // border-radius: 2.5px;
                    // border-bottom: 1px solid rgb(218, 213, 213);
                    // padding: 10px;
                    // border: 1px solid red;

                    .applicant-education-name {
                        width: 48%;
                        font-size: 12px;
                        font-weight: 300;
                        display: flex;
                        justify-content: center;
                        gap: 4px;
                        color: rgba(81, 91, 104, 1);
                        font-weight: 600;
                        // padding: 6px 8px 6px 8px;
                        border-radius: 2.5px;
                        // text-align: center;
                        padding: 6px;
                        border: 0.5px solid rgba(171, 171, 171, 1);
                        background-color: #FFD2A0;

                    }
                }

                .project-border-line {
                    width: 100%;
                    border: 1px solid rgb(218, 213, 213);
                }

                .hiretalent-content-experiance {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    color: grey;
                    padding-left: 10px;
                }


                .apply-hiretalent {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 2rem;

                    .more-details {
                        color: white;
                        font-weight: 400;
                        background: linear-gradient(to right, rgba(34, 34, 34, 1), rgba(113, 113, 113, 1));
                    }

                    h5 {
                        color: grey;
                        font-size: 14px;
                    }

                    h6 {
                        color: rgba(255, 49, 49, 1);
                        font-size: 12px;
                    }
                }






            }
        }

        .show-more-button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                background-color: #FF3131;
                padding: 12px 32px;
                border-radius: 32px;
                color: white;
                border: none;

            }
        }
    }
}

@media (max-width: 575.98px) {
    .bread-cumb-on {
        display: none;
    }

    .all-recuiter-filter-list {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;

        .all-recuiter-filter-list-inner {
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                font-size: 17px;
            }

            button {
                border: 1px solid #5E6670;
                outline: none;
                color: #5E6670;
                font-size: 14px;
                padding: 5px;
                border-radius: 5px;
            }
        }


    }

    .find-search-filter-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
        // position: relative;

        .find-search-filter-container-inner {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2rem;
            flex-wrap: wrap;

            .find-search-filter-container-filter {

                display: flex;
                flex-direction: column;
                border-radius: 0px;
                border: 1px solid rgba(217, 234, 244, 1);
                padding: 16px;
                gap: 15px;
                position: fixed;
                top: 8%;
                // height: 100vh;
                min-height: 100vh;
                left: -70%;
                background-color: white;
                z-index: 1;
                overflow-y: none;
                transition: left 0.3s ease-in-out;
                // display: none;
                width: 0%;

                // border: 1px solid red;
                .close-filter-sidebar {
                    display: block;
                }

            }

            .find-search-filter-container-filter.show-filter {
                left: 0%;
                background-color: white;
                width: 60%;
                height: auto;
                z-index: 999;
                border-radius: 0px;

                // display: none;
            }


            .all-recuiter-filter-container-card {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                // border: 1px solid green;
                gap: 1rem;
                flex-wrap: wrap;

                .filter-industry {
                    width: 100%;
                    // border: 1px solid red;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 8px;
                    font-size: 10px;
                    margin-left: 10px;

                    .industry-item {
                        border: 1px solid grey;
                        padding: 3px 10px;
                        border-radius: 22px;
                    }

                }

                .allrecuiter-content {
                    width: 95%;
                    height: auto;
                    border: 2px solid rgba(238, 238, 238, 1);
                    // border: 1px solid blue;

                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 7px;
                    padding: 1rem;
                    border-radius: 6px;

                    &:hover {
                        box-shadow: 8px 8px 18px #0000001A;
                    }

                    // box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.25);
                    //  &:hover {
                    //      box-shadow: 8px 8px 18px #0000001A;

                    //  }

                    .job-title {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h5 {
                            font-size: 14px;
                            font-weight: 500;
                            color: black;
                            text-transform: capitalize;
                        }

                        h6 {
                            font-weight: 300;
                            font-size: 12px;
                            color: rgba(171, 171, 171, 1);
                        }

                    }

                    .job-occupationskill {
                        font-size: 14px;
                        font-weight: 500;
                        color: black;
                    }

                    .job-hostedby {
                        font-size: 14px;
                        color: rgb(166, 168, 170);
                        font-weight: 300;
                    }

                    .job-category {
                        font-size: 14px;
                        color: rgba(94, 102, 112, 1);
                        font-weight: 300;
                    }

                    .hiretalent-content-about {
                        font-size: 12px;
                        text-align: justify;
                        overflow-wrap: break-word;
                        max-width: 100%;
                        color: grey;
                        white-space: normal;

                    }

                    .hiretalent-education {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        gap: 3px;
                        // border-radius: 2.5px;
                        border-bottom: 1px solid rgb(218, 213, 213);
                        // padding: 10px;

                        .applicant-education-name {
                            width: 44%;
                            font-size: 10px;
                            font-weight: 300;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            color: rgba(81, 91, 104, 1);
                            // padding: 6px 8px 6px 8px;
                            border-radius: 2.5px;
                            // text-align: center;
                            padding: 4px;
                            border: 0.5px solid rgba(171, 171, 171, 1);

                            img {
                                width: 20px;
                                height: 20px;
                                // border: 1px solid red;
                            }

                        }
                    }

                    .hiretalent-content-experiance {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        color: grey;
                        padding-left: 10px;
                    }


                    .apply-hiretalent {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 2rem;

                        .more-details {
                            color: white;
                            font-weight: 400;
                            background: linear-gradient(to right, rgba(34, 34, 34, 1), rgba(113, 113, 113, 1));
                        }

                        h6 {
                            color: rgba(255, 49, 49, 1);
                            font-size: 12px;
                        }
                    }






                }
            }
        }
    }
}