.interested{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .interested-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .interested-content{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            .interested-image{
                border-radius: 50%;
                width: 150px;
                height: 150px;
                background-color: #FFF0F0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
@media (max-width: 575.98px) {
    .interested{
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .interested-container{
            width: 90%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .interested-content{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 10px;
                .interested-image{
                    border-radius: 50%;
                    width: 150px;
                    height: 150px;
                    background-color: #FFF0F0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .profile-intrested-heading{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;
                    justify-content: center;
                    margin-top: 1rem;
                    h3{
                    font-size: 15px;
                    text-align: center;

                    }
                }
                .profile-intrested-para{
                    font-size: 13px;
                    text-align: center;

                }
            }
        }
    }  
}