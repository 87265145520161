.recuiter-model-project {

    // border: 1px solid red;
    .recuiter-model-project-header {
        // border: 1px solid blue;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;

        // align-items: flex-end;
        .recuiter-model-project-title {
            width: 100%;
            // border: 1px solid green;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
    @media (max-width: 767px) {
        .modal-content {
            max-height: 90vh;  // Limit height to 90% of the viewport height
            overflow-y: auto;  // Enable scrolling for content that exceeds height
        }

        .modal-body {
            max-height: 70vh;  // Make body scrollable while leaving space for the header
            overflow-y: auto;
        }
    }
}