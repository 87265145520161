.breacrump-conatiner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #EEEEEE;

    .breacrump-inner {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center ;
        // flex-direction: column-reverse;
        padding: 1rem;
gap: 8px;
        .breadcrumb {
            font-size: 12px;
            padding: 0%;
            margin: 0;
            .breadcrumb-item{
                a{
                    text-decoration: none;
                    color: black;
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .breacrump-conatiner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8vh;
        background-color: #EEEEEE;

        .breacrump-inner {
            width: 95%;
            display: flex;
            justify-content: space-between;
             align-items: center;
            //  flex-direction:;
            padding: 1rem;
    gap: 8px;
    .breacrump-inner-heaidng{
        h3{
            font-size: 14px;
            font-weight: 600;
        }
    }
            .breadcrumb {
                font-size: 10px;
                padding: 0%;
                margin: 0;
                .breadcrumb-item{
                    a{
                        text-decoration: none;
                        color: black;
                    }
                }
            }
        }

    }
}