.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
     
}

.upload-box {
    width: 500px;
    height: 200px;
    border: 2px dashed #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: #f9f9f9;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    transition: 0.3s;
    position: relative;
}

.upload-box:hover {
    background-color: #e6f2ff;
}

.upload-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.upload-btn {
    padding: 10px 20px;
    background-color: rgb(238, 100, 41);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s;
    margin-bottom: 2rem;
}

.upload-btn:hover {
    background-color: #0056b3;
}
